a,
img {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
a,
a:hover {
  text-decoration: none;
}
.button,
.form-control,
p {
  font-weight: 500;
}
.switch,
p {
  margin-bottom: 0;
}
body {
  font-family: CircularStd, sans-serif;
  font-size: 15px;
  background: #fdfdfd;
  color: #7a797c;
}
a {
  color: inherit;
  transition: all 0.3s;
}
a:hover {
  color: #2196f3;
}
.main .chat .content .message .text .attachment .file a:hover,
.main .start .content .callout span > a:hover {
  text-decoration: underline;
}
img {
  transition: all 0.3s;
}
.btn,
.dropdown-item {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
p {
  line-height: 1.7;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.06);
}
::-webkit-scrollbar-thumb {
  background: #e8e8e8;
}
@font-face {
  font-family: CircularStd;
  src: url(../fonts/circular/CircularStd-Black.eot);
  src: url(../fonts/circular/CircularStd-Blackd41d.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/circular/CircularStd-Black.woff) format("woff"),
    url(../fonts/circular/CircularStd-Black.ttf) format("truetype"),
    url(../fonts/circular/CircularStd-Black.svg#bcc26993292869431e54c666aafa8fcd)
      format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: CircularStd;
  src: url(../fonts/circular/CircularStd-BlackItalic.eot);
  src: url(../fonts/circular/CircularStd-BlackItalicd41d.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/circular/CircularStd-BlackItalic.woff) format("woff"),
    url(../fonts/circular/CircularStd-BlackItalic.ttf) format("truetype"),
    url(../fonts/circular/CircularStd-BlackItalic.svg#bcc26993292869431e54c666aafa8fcd)
      format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: CircularStd;
  src: url(../fonts/circular/CircularStd-Bold.eot);
  src: url(../fonts/circular/CircularStd-Boldd41d.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/circular/CircularStd-Bold.woff) format("woff"),
    url(../fonts/circular/CircularStd-Bold.ttf) format("truetype"),
    url(../fonts/circular/CircularStd-Bold.svg#bcc26993292869431e54c666aafa8fcd)
      format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: CircularStd;
  src: url(../fonts/circular/CircularStd-BoldItalic.eot);
  src: url(../fonts/circular/CircularStd-BoldItalicd41d.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/circular/CircularStd-BoldItalic.woff) format("woff"),
    url(../fonts/circular/CircularStd-BoldItalic.ttf) format("truetype"),
    url(../fonts/circular/CircularStd-BoldItalic.svg#bcc26993292869431e54c666aafa8fcd)
      format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: CircularStd;
  src: url(../fonts/circular/CircularStd-Medium.eot);
  src: url(../fonts/circular/CircularStd-Mediumd41d.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/circular/CircularStd-Medium.woff) format("woff"),
    url(../fonts/circular/CircularStd-Medium.ttf) format("truetype"),
    url(../fonts/circular/CircularStd-Medium.svg#bcc26993292869431e54c666aafa8fcd)
      format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: CircularStd;
  src: url(../fonts/circular/CircularStd-MediumItalic.eot);
  src: url(../fonts/circular/CircularStd-MediumItalicd41d.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/circular/CircularStd-MediumItalic.woff) format("woff"),
    url(../fonts/circular/CircularStd-MediumItalic.ttf) format("truetype"),
    url(../fonts/circular/CircularStd-MediumItalic.svg#bcc26993292869431e54c666aafa8fcd)
      format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: CircularStd;
  src: url(../fonts/circular/CircularStd-Book.eot);
  src: url(../fonts/circular/CircularStd-Bookd41d.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/circular/CircularStd-Book.woff) format("woff"),
    url(../fonts/circular/CircularStd-Book.ttf) format("truetype"),
    url(../fonts/circular/CircularStd-Book.svg#bcc26993292869431e54c666aafa8fcd)
      format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: CircularStd;
  src: url(../fonts/circular/CircularStd-BookItalic.eot);
  src: url(../fonts/circular/CircularStd-BookItalicd41d.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/circular/CircularStd-BookItalic.woff) format("woff"),
    url(../fonts/circular/CircularStd-BookItalic.ttf) format("truetype"),
    url(../fonts/circular/CircularStd-BookItalic.svg#bcc26993292869431e54c666aafa8fcd)
      format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Material Icons";
  src: url(../fonts/material/MaterialIcons-Regular.eot);
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(../fonts/material/MaterialIcons-Regular.woff2) format("woff2"),
    url(../fonts/material/MaterialIcons-Regular.woff) format("woff"),
    url(../fonts/material/MaterialIcons-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
.form-control {
  padding: 25px 15px;
  background: #f5f5f5;
  width: 100% !important;
  border-radius: 6px;
  border: none;
  font-size: 16px;
  color: #bdbac2 !important;
}
.form-control:focus {
  background: #f5f5f5;
  border: none !important;
  box-shadow: none;
}
.btn,
.btn:active,
.btn:focus,
.btn:hover,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active,
.show > .btn.dropdown-toggle {
  border: none;
  box-shadow: none;
  outline: 0;
}
.form-control::placeholder {
  font-size: 16px;
  font-weight: 500;
  color: #bdbac2;
}
.btn {
  background: 0 0;
  transition: all 0.3s;
}
.button {
  padding: 16px 25px;
  width: 100%;
  background: #2196f3;
  color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.04) !important;
}
.avatar-lg,
.avatar-md,
.avatar-sm,
.avatar-xl {
  box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.04);
}
.button:hover {
  color: #fff;
  opacity: 0.9;
}
.bg-blue {
  background: #2196f3 !important;
}
.bg-indigo {
  background: #6610f2 !important;
}
.bg-purple {
  background: #6f42c1 !important;
}
.bg-red {
  background: #dc3545 !important;
}
.bg-pink {
  background: #e83e8c !important;
}
.bg-orange {
  background: #fd7e14 !important;
}
.bg-gray {
  background: #bdbac2 !important;
}
.bg-green {
  background: #28a745 !important;
}
.bg-teal {
  background: #20c997 !important;
}
.bg-yellow {
  background: #ffc107 !important;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-30 {
  font-size: 30px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 42px;
  max-width: 42px;
  height: 18px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3 !important;
}
input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.tooltip {
  font-size: 15px;
  font-weight: 600;
}
.tooltip.show {
  opacity: 1;
}
.tooltip-inner {
  padding: 5px 12px;
  background: #212529;
  color: #fff;
  border-radius: 6px;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #212529;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #212529;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #212529;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #212529;
}
.layout {
  overflow: hidden;
}
.main .call,
.main .chat .content {
  -ms-overflow-style: none;
  overflow-x: hidden;
}
.avatar-sm {
  width: 100%;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  border: 2px solid #fff;
  border-radius: 100%;
}
.avatar-md {
  width: 100%;
  height: 45px;
  min-width: 45px;
  max-width: 45px;
  border: 2px solid #fff;
  border-radius: 100%;
}
.avatar-lg {
  width: 100%;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  border: 3px solid #fff;
  border-radius: 100%;
}
.avatar-xl {
  width: 100%;
  height: 60px;
  min-width: 60px;
  max-width: 60px;
  border: 3px solid #fff;
  border-radius: 100%;
}
.avatar-xxl {
  width: 100%;
  height: 200px;
  min-width: 200px;
  max-width: 200px;
  border-radius: 100%;
}
.align-ctr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.dropdown-menu {
  min-width: 14rem;
  padding: 10px;
  margin-top: 10px;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  border: none;
}
.dropdown-item,
.dropdown-item:focus,
.dropdown-item:hover {
  color: #bdbac2;
  border-radius: 6px;
}
.dropdown-menu hr {
  border-top: 2px solid #fbfbfb;
  margin: 10px;
}
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s;
}
.dropdown-item:hover {
  background: #f5f5f5;
}
.dropdown-item:focus {
  background: 0 0;
  border: none;
  outline: 0;
  box-shadow: none;
}
.create.btn:not(:disabled):not(.disabled):active,
.show > .btn.dropdown-toggle,
.sidebar .create,
.sidebar .create.btn:not(:disabled):not(.disabled).active {
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.04);
}
.dropdown-item.active,
.dropdown-item:active {
  background: #2196f3 !important;
  color: #fff !important;
}
.dropdown-menu .material-icons {
  margin-right: 10px;
}
.sidebar .contacts .contact .avatar-md,
.sidebar .discussions .single .avatar-md,
.sidebar .notifications .notification .avatar-md {
  margin-right: 15px;
}
.navigation {
  background: #fff;
}
.navigation .inside .menu .btn {
  padding: 0;
  color: inherit;
}
.navigation .inside .menu .btn:last-child {
  margin-bottom: 30px;
}
.navigation .inside .menu .avatar-xl:hover {
  border: 3px solid #2196f3;
}
.navigation .inside .menu .material-icons {
  display: block;
  font-size: 45px;
  margin-bottom: 40px;
}
.navigation .inside .menu .material-icons.active {
  color: #2196f3;
}
.navigation .inside .menu .power:hover {
  color: #dc3545;
}
.navigation .inside .menu .power .material-icons {
  display: block;
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 0;
}
.navigation .inside .menu .mode:hover {
  color: #2196f3;
}
.sidebar {
  background: #fdfdfd;
}
.sidebar .search {
  margin-bottom: 30px;
}
.sidebar .search .form-control {
  padding: 0 15px 0 54px;
  height: 56px;
}
.sidebar .search .loop {
  position: absolute;
  padding: 0 15px;
  top: 0;
  bottom: 0;
  left: 0;
  line-height: 0;
  margin-bottom: 0 !important;
}
.sidebar .search .loop .material-icons {
  font-size: 25px;
  font-weight: 600;
  color: #bdbac2;
}
.sidebar .search .loop .material-icons.filter-list {
  font-weight: 400;
}
.sidebar .sort {
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}
.sidebar .sort .btn {
  padding: 8px 20px;
  background: 0 0;
  border-radius: 100px;
  color: inherit;
  font-size: 15px;
  font-weight: 500;
}
.sidebar .sort .btn.active {
  color: #2196f3;
  padding: 8px 20px;
  background: #f5f5f5;
  border-radius: 100px;
}
.sidebar .create {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  bottom: 0;
  top: 30px;
  right: 20px;
  float: right;
  background: #fff;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
.sidebar .create:hover {
  -webkit-transform: translateY(-5px) translateZ(0);
  transform: translateY(-5px) translateZ(0);
}
.sidebar .create .material-icons {
  font-size: 22px;
  color: #bdbac2;
}
.sidebar .contacts h1 {
  font-size: 24px;
  font-weight: 800;
  color: #212529;
  border-bottom: 2px solid #fbfbfb;
  padding-bottom: 18px;
  margin-bottom: 0;
}
.sidebar .contacts a:hover {
  color: inherit;
}
.sidebar .contacts .contact .status .online,
.sidebar .contacts .contact.active .material-icons {
  color: #2196f3;
}
.sidebar .contacts .contact {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
  border-bottom: 2px solid #fbfbfb;
}
.sidebar .contacts .contact:hover {
  border-bottom: 2px solid #2196f3;
}
.sidebar .contacts .contact:last-of-type {
  border-bottom: 2px solid transparent !important;
}
.sidebar .contacts .contact.active {
  border-bottom: 2px solid #2196f3;
}
.sidebar .contacts .contact .status {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  left: 4px;
  border: 5px solid #fff;
  margin-top: 0;
  border-radius: 100%;
}
.sidebar .contacts .contact .status .offline {
  color: #bbb;
}
.sidebar .contacts .contact .status .material-icons {
  position: absolute;
  left: -3px;
  bottom: -3px;
  font-size: 16px;
}
.sidebar .contacts .contact .data {
  width: 100%;
}
.sidebar .contacts .contact .data h5 {
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  color: #212529;
  margin-bottom: 5px;
}
.sidebar .contacts .contact .data p {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 400;
}
.sidebar .discussions h1 {
  font-size: 24px;
  font-weight: 800;
  color: #212529;
  border-bottom: 2px solid #fbfbfb;
  padding-bottom: 18px;
  margin-bottom: 0;
}
.sidebar .discussions .single.active p,
.sidebar .discussions .single.active span,
.sidebar .discussions .single.unread p,
.sidebar .discussions .single.unread span {
  font-weight: 500 !important;
  color: #212529;
}
.sidebar .discussions a:hover {
  color: inherit;
}
.sidebar .discussions .single {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 2px solid #fbfbfb;
}
.sidebar .discussions .single:hover {
  border-bottom: 2px solid #2196f3;
}
.sidebar .discussions .single.active {
  border-bottom: 2px solid #2196f3;
}
.sidebar .discussions .single.active .material-icons {
  color: #212529;
}
.sidebar .discussions .single .new {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 30px;
  height: 30px;
  background: #f0d24b;
  border: 2px solid #fff;
  border-radius: 100%;
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.04);
  right: 0;
  left: 39px;
  margin-top: -12px;
}
.sidebar .discussions .single .new span {
  color: #fff !important;
  font-size: 12px;
  font-weight: 500;
}
.sidebar .discussions .single .status {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  left: 4px;
  border: 5px solid #fff;
  margin-top: 12px;
  border-radius: 100%;
}
.sidebar .discussions .single .status .online {
  color: #2196f3;
}
.sidebar .discussions .single .status .offline {
  color: #bbb;
}
.sidebar .discussions .single .status .material-icons {
  position: absolute;
  left: -3px;
  bottom: -3px;
  font-size: 16px;
}
.sidebar .discussions .single .data {
  width: 100%;
}
.sidebar .discussions .single .data h5 {
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  color: #212529;
  margin-bottom: 8px;
}
.sidebar .discussions .single .data p {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
}
.sidebar .discussions .single .data span {
  float: right;
  font-size: 14px;
  font-weight: 400;
}
.sidebar .notifications h1 {
  font-size: 24px;
  font-weight: 800;
  color: #212529;
  border-bottom: 2px solid #fbfbfb;
  padding-bottom: 18px;
  margin-bottom: 0;
}
.sidebar .notifications a:hover {
  color: inherit;
}
.sidebar .notifications .notification {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 19px 0;
  border-bottom: 2px solid #fbfbfb;
}
.sidebar .notifications .notification:hover {
  border-bottom: 2px solid #2196f3;
}
.sidebar .notifications .notification:last-of-type {
  border-bottom: 2px solid transparent !important;
}
.sidebar .notifications .notification.active {
  border-bottom: 2px solid #2196f3;
}
.sidebar .notifications .notification.active .material-icons {
  color: #212529;
}
.sidebar .notifications .notification .status {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  left: 4px;
  border: 5px solid #fff;
  margin-top: 12px;
  border-radius: 100%;
}
.sidebar .notifications .notification .status .online {
  color: #2196f3;
}
.sidebar .notifications .notification .status .offline {
  color: #bbb;
}
.sidebar .notifications .notification .status .material-icons {
  position: absolute;
  left: -3px;
  bottom: -3px;
  font-size: 16px;
}
.sidebar .notifications .notification .data {
  width: 100%;
}
.sidebar .notifications .notification .data p {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
  color: #212529;
  margin-bottom: 7px;
}
.sidebar .notifications .notification .data span {
  float: left;
  font-size: 15px;
  color: #bdbac2;
}
.sidebar .settings .profile {
  text-align: center;
  margin-bottom: 40px;
}
.sidebar .settings .profile .avatar-xl {
  margin-bottom: 20px;
}
.sidebar .settings .profile h1 {
  font-size: 18px;
  font-weight: 700;
  color: #212529;
  margin-bottom: 8px;
}
.sidebar .settings .profile span {
  display: block;
  margin-bottom: 31px;
  font-size: 15px;
}
.sidebar .settings .profile .stats {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.sidebar .settings .profile .stats:hover {
  -webkit-transform: translateY(-5px) translateZ(0);
  transform: translateY(-5px) translateZ(0);
}
.sidebar .settings .profile .stats .item {
  width: 100%;
  text-align: center;
  border-right: 2px solid #fbfbfb;
}
.sidebar .settings .profile .stats .item:last-child {
  border-right: none;
}
.sidebar .settings .profile .stats .item h2 {
  font-size: 20px;
  font-weight: 600;
  color: #212529;
  margin-bottom: 6px;
}
.sidebar .settings .profile .stats .item h3 {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  color: #bdbac2;
}
.sidebar .settings .categories h1 {
  font-size: 24px;
  font-weight: 800;
  color: #212529;
  border-bottom: 2px solid #fbfbfb;
  padding-bottom: 23px;
  margin-bottom: 0;
}
.sidebar .settings .categories .category .title {
  display: flex;
  align-items: center;
  padding: 21px 0 20px;
  border-bottom: 2px solid #fbfbfb;
}
.sidebar .settings .categories .category .title:last-child {
  border-bottom: 2px solid transparent;
}
.sidebar .settings .categories .category .active {
  color: #2196f3;
}
.sidebar .settings .categories .category .title .data {
  width: 100%;
  margin-left: 15px;
  margin-right: auto !important;
}
.sidebar .settings .categories .category .title .data h5 {
  font-size: 16px;
  font-weight: 700;
  color: #212529;
  margin-bottom: 5px;
}
.sidebar .settings .categories .category .title .data p {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 400;
  color: #bdbac2;
}
.sidebar .settings .categories .category .content {
  padding: 30px 0;
  border-bottom: 2px solid #fbfbfb;
}
.sidebar .settings .categories .category .content .button {
  padding: 14px 25px;
}
.sidebar .settings .categories .category .content .btn-link {
  padding: 0;
  color: #dc3545;
  font-weight: 500;
  margin-bottom: 18px;
}
.sidebar .settings .categories .category .content.layer {
  padding: 24px 0 30px !important;
}
.sidebar .settings .categories .category .content.no-layer {
  padding: 28px 0 27px !important;
}
.sidebar .settings .categories .category .content .upload {
  padding: 27px 25px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  margin-bottom: 30px;
}
.sidebar .settings .categories .category .content .upload p {
  font-size: 15px;
  font-weight: 400;
}
.sidebar .settings .categories .category .content .upload .data {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}
.sidebar .settings .categories .category .content .upload .data label {
  margin-bottom: 0;
}
.sidebar .settings .categories .category .content .upload .data input {
  display: none;
}
.sidebar .settings .categories .category .content .upload .data .avatar-xl {
  margin-right: 20px;
}
.sidebar .settings .categories .category .content .data .button {
  padding: 10px 25px;
}
.sidebar .settings .categories .category .content .parent {
  display: flex;
}
.sidebar .settings .categories .category .content .parent .field {
  margin-right: 15px;
}
.sidebar .settings .categories .category .content .parent .field:last-child {
  margin-right: 0;
  margin-bottom: 20px;
}
.sidebar .settings .categories .category .content .field {
  width: 100%;
  margin-bottom: 20px;
}
.sidebar .settings .categories .category .content .field:last-of-type {
  margin-bottom: 30px;
}
.sidebar .settings .categories .category .content .field label {
  font-size: 14px;
  font-weight: 700;
  color: #212529;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.sidebar .settings .categories .category .content .field label > span {
  font-weight: 400;
  color: #dc3545;
}
.sidebar .settings .categories .category .content .field .form-control {
  padding: 0 18px;
  height: 52px;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid transparent;
}
.sidebar .settings .categories .category .content .field .form-control:focus {
  border: 2px solid #2196f3 !important;
}
.sidebar
  .settings
  .categories
  .category
  .content
  .field
  .form-control::placeholder {
  font-weight: 400;
  font-size: 16px;
}
.sidebar .settings .categories .category .content .history p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.sidebar .settings .categories .category .content .history p:last-of-type {
  margin-bottom: 20px;
}
.sidebar .settings .categories .category .content .history .custom-control {
  margin-bottom: 10px;
}
.sidebar
  .settings
  .categories
  .category
  .content
  .history
  .custom-control:last-of-type {
  margin-bottom: 20px;
}
.sidebar
  .settings
  .categories
  .category
  .content
  .history
  .custom-control-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
}
.sidebar
  .settings
  .categories
  .category
  .content
  .history
  .custom-control-label::before {
  background-color: #f5f5f5;
  box-shadow: none;
  border: none;
}
.sidebar
  .settings
  .categories
  .category
  .content
  .history
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}
.sidebar .settings .categories .category .content .app {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 27px 25px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
}
.sidebar .settings .categories .category .content .app:last-child {
  margin-bottom: 0;
}
.sidebar .settings .categories .category .content .app img {
  width: 36px;
  height: 36px;
  margin-right: 15px;
}
.sidebar .settings .categories .category .content .app .permissions {
  margin-right: auto !important;
}
.sidebar .settings .categories .category .content .app .permissions h5 {
  font-size: 16px;
  font-weight: 600;
  color: #212529;
  margin-bottom: 8px;
}
.sidebar .settings .categories .category .content .app .permissions p {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 400;
}
.sidebar .settings .categories .category .content .language label {
  font-size: 14px;
  font-weight: 700;
  color: #212529;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.sidebar .settings .categories .category .content .language .custom-select {
  padding: 0 18px;
  background: #f5f5f5;
  width: 100%;
  border-radius: 6px;
  height: 52px;
  color: #bdbac2;
  font-weight: 500;
  font-size: 16px;
  border: 2px solid transparent;
}
.sidebar
  .settings
  .categories
  .category
  .content
  .language
  .custom-select:focus {
  border: 2px solid #2196f3 !important;
  box-shadow: none !important;
}
.sidebar .settings .categories .category .content .language select > option {
  font-weight: 400;
}
.sidebar .settings .categories .category .content .set {
  display: flex;
  align-items: flex-start;
  padding: 21px 0 20px;
  border-bottom: 2px solid #fbfbfb;
}
.sidebar .settings .categories .category .content .set:only-child {
  padding: 0 !important;
}
.sidebar .settings .categories .category .content .set:first-child {
  padding: 0 0 20px;
}
.sidebar .settings .categories .category .content .set:last-child {
  padding: 21px 0 0;
  border-bottom: none;
}
.sidebar .settings .categories .category .content .set .details {
  margin-right: auto;
}
.sidebar .settings .categories .category .content .set .details h5 {
  font-size: 16px;
  font-weight: 600;
  color: #212529;
  margin-bottom: 8px;
}
.sidebar .settings .categories .category .content .set .details p {
  font-size: 15px;
  line-height: 1.4;
  font-weight: 400;
}
.modal-dialog {
  max-width: 450px;
}
.modal-backdrop {
  opacity: 0 !important;
}
.requests {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  pointer-events: auto;
}
.requests .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fdfdfd;
  padding: 30px 40px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.requests .title h1 {
  font-size: 22px;
  font-weight: 800;
  color: #212529;
  margin-bottom: 0;
}
.requests .title .material-icons {
  font-weight: 800;
}
.requests .title .close:hover {
  color: #212529 !important;
}
.requests .content {
  padding: 35px 40px 40px;
}
.requests .content .form-group {
  margin-bottom: 30px;
}
.requests .content .form-group:last-of-type {
  margin-bottom: 33px;
}
.requests .content .form-group label {
  font-size: 14px;
  font-weight: 700;
  color: #212529;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.requests .content .form-group .form-control {
  padding: 0 18px;
  height: 56px;
  font-weight: 400;
  border: 2px solid transparent;
}
.requests .content .form-group .form-control:focus {
  border: 2px solid #2196f3 !important;
}
.requests .content .form-group .form-control::placeholder {
  font-weight: 400;
}
.requests .content .text-control {
  padding: 14px 17px;
  background: #f5f5f5;
  width: 100% !important;
  height: 100%;
  min-height: 100px;
  max-height: 150px;
  border-radius: 6px !important;
  font-size: 16px;
  font-weight: 400;
  color: #bdbac2 !important;
  border: 2px solid transparent;
}
.requests .content .text-control::placeholder {
  color: #bdbac2;
}
.requests .content .text-control:focus {
  border-radius: 6px;
  border: 2px solid #2196f3;
  outline: 0;
}
.requests .content .user {
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: -47px;
  left: 49px;
  background: #fdfdfd;
  padding: 4px 8px;
  border-radius: 6px;
}
.requests .content .user .avatar-sm {
  margin-right: 10px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.04);
}
.requests .content .user h5 {
  font-size: 15px;
  font-weight: 600;
  color: #212529;
  line-height: 1.7;
  margin-bottom: 0;
  margin-right: 6px;
}
.requests .btn {
  padding: 0;
  line-height: 0;
}
.requests .content .user .btn .material-icons {
  font-size: 16px;
  font-weight: 600;
}
.requests .content .button {
  padding: 16px 25px;
  line-height: 1.5;
}
.main {
  background: #fdfdfd;
  width: 100%;
}
.main .chat {
  height: 100%;
}
.main .chat .btn {
  padding: 0;
  line-height: 0;
}
.btn:disabled,
.main .chat .btn.disabled {
  opacity: 1;
}
.main .chat .btn.disabled:hover {
  opacity: 1;
  color: inherit !important;
}
.main .chat .attach {
  background: #2196f3;
  color: #fff;
  border-radius: 100%;
}
.main .chat .attach:hover {
  color: #fff;
  opacity: 0.8;
}
.main .chat .top {
  padding: 26.5px 0;
  background: #fff;
  border-bottom: 2px solid #fbfbfb;
}
.main .chat .top .inside {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.main .chat .top .inside .avatar-md {
  margin-right: 15px;
}
.main .chat .top .inside .status {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  left: 4px;
  border: 5px solid #fff;
  border-radius: 100%;
  margin-top: 0;
  margin-right: 10px;
}
.main .chat .top .inside .status .online {
  color: #2196f3;
}
.main .chat .top .inside .status .offline {
  color: #bbb;
}
.main .chat .top .inside .status .material-icons {
  position: absolute;
  left: -3px;
  bottom: -3px;
  font-size: 16px;
}
.main .chat .top .inside .data {
  margin-right: auto;
}
.main .chat .top .inside .data h5 {
  font-size: 16px;
  font-weight: 700;
  color: #212529;
  margin-bottom: 2px;
}
.main .chat .top .inside .data span {
  display: block;
  font-size: 15px;
  line-height: 1.4;
  font-weight: 400;
}
.main .chat .top .inside .btn {
  padding: 0;
  line-height: 0;
  color: inherit;
  margin-right: 30px;
}
.main .chat .top .inside .btn:hover {
  color: #2196f3;
}
.main .chat .top .inside .dropdown .btn {
  margin-right: 0;
}
.main .chat .top .inside .dropdown-menu-right {
  right: 0 !important;
  bottom: inherit !important;
}
.main .chat .content {
  display: flex;
  padding: 20px 0 0;
  height: calc(90%);
  overflow-y: scroll;
}
.main .chat .content > div {
  margin-top: auto;
}
.main .chat .content::-webkit-scrollbar {
  display: none;
}
.main .chat .content.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow-y: auto;
}
.main .chat .content.empty > div {
  margin-top: auto;
  margin-bottom: auto;
}
.main .chat .content .date {
  display: flex;
  align-items: center;
  padding: 26px 0 46px;
}
.main .chat .content .date hr {
  width: 100%;
  border-top: 2px solid #fbfbfb;
  margin-top: 0;
  margin-bottom: 0;
}
.main .chat .content .date span {
  display: block;
  padding: 0 20px;
}
.main .chat .content .message {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 20px;
}
.main .chat .content .message:last-child {
  margin-bottom: 50px;
}
.main .chat .content .message.me {
  justify-content: flex-end;
}
.main .chat .content .message.me:last-child {
  margin-bottom: 43px;
}
.main .chat .content .message .avatar-md {
  margin-right: 15px;
}
.main .chat .content .message .text-group {
  width: 100%;
  margin-bottom: 10px;
}
.main .chat .content .message .text-group.me {
  display: flex;
  justify-content: flex-end;
}
.main .chat .content .message .text {
  display: inline-block;
  padding: 15px;
  max-width: 450px;
  background: #f5f5f5;
  border-radius: 6px;
}
.main .chat .content .message .text.typing {
  padding: 8px 12px;
}
.main .chat .content .message .text.typing .wave .dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-right: 1px;
  background: #bdbac2;
  animation: wave 1.3s linear infinite;
}
.main .chat .content .message .text.typing .wave .dot:last-of-type {
  margin-right: 0;
}
.main .chat .content .message .text.typing .wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}
.main .chat .content .message .text.typing .wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}
@keyframes wave {
  0%,
  100%,
  60% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}
.main .chat .content .message .text.me {
  background: #2196f3;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.main .call .content .options .option.call-end:hover,
.main .chat .content .no-messages .options .button:hover {
  transform: scale(1.05);
  transition: all 0.3s linear;
}
.main .chat .content .message .text.me .attachment .attach {
  background: #fff;
  color: #2195f3;
}
.main .chat .content .message .text.me .attachment .file a:hover,
.main .chat .content .message .text.me .attachment .file h5,
.main .chat .content .message .text.me p {
  color: #fff;
}
.main .chat .content .message .text.me .attachment .file span {
  color: #fff;
  text-align: left;
}
.main .chat .content .message .text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}
.main .chat .content .message .text .attachment {
  display: flex;
  align-items: center;
}
.main .chat .content .message .text .attachment .attach {
  padding: 12px;
  width: 42px;
  height: 42px;
  margin-right: 15px;
}
.main .chat .content .message .text .attachment .file h5 {
  font-size: 16px;
  font-weight: 400;
  color: #2196f3;
  line-height: 1.4;
  margin-bottom: 5px;
}
.main .chat .content .message span {
  display: block;
  font-size: 14px;
  font-weight: 400;
}
.main .chat .content .message.me span {
  text-align: right;
}
.main .chat .content .message span > .material-icons {
  position: relative;
  top: 2px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
}
.main .chat .content .no-messages {
  display: block;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 47px auto 45px;
}
.main .chat .content .no-messages.request {
  max-width: 420px;
  margin: 50px auto;
}
.main .chat .content .no-messages .material-icons {
  font-weight: 600;
  margin-bottom: 20px;
}
.main .chat .content .no-messages .avatar-xl {
  margin-bottom: 32px;
}
.main .chat .content .no-messages h5 {
  display: block;
  font-size: 18px;
  line-height: 1.7;
  font-weight: 400;
  margin-bottom: 35px;
}
.main .chat .bottom label,
.main .chat .content .no-messages .options .button .material-icons {
  margin-bottom: 0;
}
.main .chat .content .no-messages h5 > span {
  font-size: 18px;
  line-height: 1.7;
}
.main .chat .content .no-messages p {
  font-size: 18px;
  font-weight: 400;
}
.main .chat .content .no-messages .options {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main .chat .content .no-messages .options .button {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.04) !important;
}
.main .chat .content .no-messages .options .button + .button {
  margin-left: 20px;
}
.main .chat .content .no-messages .options .button:nth-child(2) {
  background: #dc3545;
}
.main .chat .bottom {
  display: flex;
  align-items: center;
  padding: 0 0;
  border-top: 2px solid #fbfbfb;
}
.main .call,
.main .chat .bottom input {
  display: none;
}
.main .chat .bottom .form-control {
  padding: 16px 58px;
  height: 56px;
  resize: none;
  font-weight: 400;
}
.main .chat .bottom .form-control::placeholder {
  font-weight: 400;
}
.form-control[readonly],
.main .chat .bottom .form-control:disabled {
  background-color: #f5f5f5;
}
.main .chat .bottom .btn {
  padding: 0;
  line-height: 0;
  color: #bdbac2;
}
.main .chat .bottom .btn:hover {
  color: #2196f3;
}
.main .chat .bottom .btn.emoticons {
  padding: 0 15px 0 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.main .chat .bottom .btn.send {
  padding: 0 20px 0 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.main .chat .bottom .btn.attach {
  padding: 16px;
  width: 56px;
  height: 56px;
  color: #fff;
  margin-left: 20px;
  box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.04);
}
.main .chat .bottom .btn.attach:hover {
  color: #fff;
}
.main .chat .bottom .btn.attach.btn.disabled:hover {
  color: #fff !important;
}
.main .call {
  background: linear-gradient(
    145deg,
    rgba(140, 167, 255, 1) 0,
    rgba(139, 84, 249, 1) 50%,
    rgba(131, 33, 243, 1) 100%
  );
  position: sticky;
  top: 0;
  z-index: 1020;
  overflow-y: auto;
}
.main .call::-webkit-scrollbar {
  display: none;
}
.main .call .content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.main .call .content .avatar-xxl {
  margin-bottom: 56px;
  cursor: pointer;
  animation: pulse 2s infinite;
}
.main .call .content .participant {
  margin-bottom: 56px;
}
.main .call .content .participant span {
  display: block;
  color: #fff;
  font-weight: 500;
  text-align: center;
}
.main .call .content .options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}
.main .call .content .options .option {
  padding: 0 20px;
  color: #fff;
  line-height: 0;
}
.main .call .content .options .option:hover {
  opacity: 0.9;
}
.main .call .content .options .option.call-end {
  padding: 15px;
  margin: 0 20px;
  background: #e05b5d;
  color: #fff;
  line-height: 0;
  border-radius: 100%;
  animation: call-end 2s infinite;
}
.main .call .content .back {
  padding: 18px;
  background: rgba(0, 0, 0, 0.05);
  color: #fff;
  line-height: 0;
  border-radius: 100%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.main .call .content .back:hover {
  -webkit-transform: translateY(10px) translateZ(0);
  transform: translateY(10px) translateZ(0);
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 50px transparent;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    -moz-box-shadow: 0 0 0 50px transparent;
    box-shadow: 0 0 0 50px transparent;
  }
}
@-webkit-keyframes call-end {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px transparent;
  }
}
@keyframes call-end {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    -moz-box-shadow: 0 0 0 20px transparent;
    box-shadow: 0 0 0 20px transparent;
  }
}
.start {
  padding-bottom: 0;
}
.main .start {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.main .start .content {
  width: 100%;
  text-align: center;
}
.main .start .content h1 {
  font-size: 46px;
  font-weight: 800;
  color: #212529;
  margin-bottom: 40px;
}
.main .start .content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.main .start .content .third-party {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 38px;
}
.main .start .content .third-party .item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  margin-right: 20px;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.main .start .content .third-party .item:hover {
  opacity: 0.9;
  -webkit-transform: translateY(-5px) translateZ(0);
  transform: translateY(-5px) translateZ(0);
}
.main .start .content .third-party .item:last-of-type {
  margin-right: 0;
}
.main .start .content .third-party .item .material-icons {
  color: #fff;
  font-size: 30px;
}
.main .start .content .third-party .item img {
  width: 25px;
  height: 25px;
}
.main .start .content form {
  width: 100%;
  max-width: 366px;
  margin: 0 auto;
}
.main .start .content .form-group {
  position: relative;
  margin-bottom: 15px;
}
.main .start .content .form-group .form-control {
  padding: 0 20px 0 58px;
  height: 56px;
  font-weight: 400;
  border: 2px solid transparent;
}
.main .start .content .form-group .form-control:focus {
  border: 2px solid #2196f3 !important;
}
.main .start .content .form-group .form-control::placeholder {
  font-weight: 400;
}
.main .start .content .form-group .btn.icon {
  position: absolute;
  padding: 0 10px 0 20px;
  top: 0;
  bottom: 0;
  left: 0;
  line-height: 0;
  color: #bdbac2;
}
.main .start .content .callout span {
  font-size: 16px;
}
.main .start .content .callout span > a {
  color: #2196f3;
}
.aside {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 550px;
  max-width: 550px;
  height: 100vh;
  background: linear-gradient(
    145deg,
    rgba(140, 167, 255, 1) 0,
    rgba(139, 84, 249, 1) 50%,
    rgba(131, 33, 243, 1) 100%
  );
}
.aside .preference {
  width: 100%;
  max-width: 340px;
  margin: 0 auto;
  text-align: center;
}
.aside .preference h2 {
  font-size: 46px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 23px;
}
.aside .preference p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 30px;
}
.aside .preference .button {
  max-width: 300px;
  background: #fff;
  color: #2196f3;
  box-shadow: none !important;
}
@media (max-width: 1200px) {
  .aside {
    width: 100%;
    min-width: auto;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .navigation .inside,
  .sidebar {
    padding: 30px 0 0;
    height: 100vh;
  }
  .navigation,
  .sidebar {
    position: sticky;
    top: 0;
    z-index: 1020;
    width: 100%;
    -ms-overflow-style: none;
    overflow-x: hidden;
  }
  .main,
  .main .start,
  .sidebar {
    height: 100vh;
  }
  .layout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .navigation {
    min-width: 100px;
    max-width: 100px;
    overflow-y: auto;
    border-right: 2px solid #fbfbfb;
  }
  .navigation::-webkit-scrollbar {
    display: none;
  }
  .navigation .inside {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navigation .inside .menu {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
  }
  .main .start .content .callout,
  .sidebar .settings .categories .category:last-of-type {
    display: none;
  }
  .navigation .inside .menu .f-grow1 {
    flex-grow: 1;
  }
  .navigation .inside .menu .avatar-xl {
    margin-bottom: 50px;
  }
  .sidebar {
    min-width: 372px;
    max-width: 372px;
    overflow-y: scroll;
  }
  .sidebar::-webkit-scrollbar {
    width: 5px;
  }
  .sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
  .sidebar::-webkit-scrollbar-thumb {
    background: #f5f5f5;
  }
  .main .chat {
    border-left: 2px solid #fbfbfb;
  }
  .main .start .content form.signup {
    max-width: 466px;
  }
  .main .start .content .form-parent {
    display: flex;
  }
  .main .start .content .form-parent .form-group {
    margin-right: 15px;
  }
  .main .start .content .form-parent .form-group:last-child {
    margin-right: 0;
  }
  .main .call .content .inside {
    max-height: calc(100vh - 0px);
  }
  .main .call .content .inside .panel {
    padding: 30px 0;
  }
}
@media (max-width: 991px) {
  body {
    padding-bottom: 54px;
  }
  .navigation {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.04);
  }
  .navigation .inside {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding: 12px 15px;
  }
  .aside,
  .navigation .inside .menu .avatar-xl,
  .navigation .inside .menu .mode,
  .navigation .inside .menu .power {
    display: none;
  }
  .navigation .inside .menu {
    flex-wrap: unset !important;
    align-items: flex-end;
    width: 100%;
  }
  .navigation .inside .menu a {
    margin-left: auto;
    margin-right: auto;
  }
  .navigation .inside .menu a:first-of-type {
    margin-left: 0;
  }
  .navigation .inside .menu a:last-of-type {
    margin-right: 0;
  }
  .navigation .inside .menu .material-icons {
    font-size: 30px;
    margin-bottom: 0;
  }
  .sidebar {
    padding: 30px 0 0;
    height: 100%;
    min-height: 100px;
    max-height: 600px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: hidden;
  }
  .sidebar::-webkit-scrollbar {
    display: none;
  }
  .main .start {
    padding: 73px 0 77px;
  }
  .main .start .content .button {
    margin-bottom: 20px;
  }
  .main .start .content .callout {
    display: block;
  }
  .main .chat .content {
    height: 600px;
  }
  .main .call .content {
    padding: 60px 0;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .main .chat .top {
    padding: 20px 0;
  }
  .main .chat .content {
    height: 500px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 468px) {
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  .requests .title {
    padding: 30px 20px;
  }
  .requests .content {
    padding: 34px 20px 40px;
  }
  .requests .content .user {
    left: 29px;
  }
  .main .start .content h1 {
    font-size: 36px;
  }
  .main .call .content .options .option {
    padding: 0 15px;
  }
}
@media (max-width: 350px) {
  .main .call .content .options .option.call-end {
    margin: 0 15px;
  }
  .main .call .content .options .option {
    padding: 0 12px;
  }
}
