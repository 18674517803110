:root {
  --background: #dadada; /** #f7f5d9; **/
  --background-secondary: #c2c1c1;
  --text-primary: black;
  --text-secondary: #313033;
  --accent: purple;
  --image: 100%;
}
[data-theme="dark"] {
  --background: rgb(24, 26, 27);
  --background-secondary: rgb(34, 36, 38);
  --text-primary: #eee;
  --text-secondary: #bdbac2;
  --accent: darkred;
  --image: 80%;
}
/* body {
  color: var(--text-secondary);
} */
/* DARK THEME */

.sidebar .discussions .single {
  border-bottom: 2px solid var(--text-primary);
  padding: 15px 0;
}

.navigation,
.main,
.layout,
.sidebar {
  background-color: var(--background);
}

.navigation,
.sidebar {
  border-right: 2px solid var(--text-secondary);
}

.main .chat .content .message .text,
.main .chat .bottom .form-control {
  background-color: var(--background-secondary);
  color: var(--text-secondary);
}

.main .chat .bottom textarea {
  color: var(--text-secondary) !important;
}

.main .chat .bottom textarea::placeholder {
  color: var(--text-secondary) !important;
}

.main .chat .content .message .info {
  color: rgb(240, 239, 239);
}

.main .chat .bottom .btn {
  color: var(--text-secondary);
}

.sidebar .discussions .single .data h5,
.sidebar .discussions .single .data p,
.sidebar .discussions h1 {
  color: var(--text-primary);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input#menu {
  display: none;
}

@media only screen and (max-width: 990px) {
  .icon {
    cursor: pointer;
    display: block;
    height: 24px;
    padding: 16px;
    width: 24px;
  }

  .icon .menu,
  .icon .menu::before,
  .icon .menu::after {
    content: "";
    display: block;
    background-color: rgb(51, 95, 51);
    height: 2px;
    position: absolute;
    z-index: 20;
    transition: background ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
    width: 20px;
  }

  .icon:hover .menu,
  .icon:hover .menu::before,
  .icon:hover .menu::after {
    background: #47b74b;
  }

  .icon .menu {
    left: 18px;
    top: 27px;
  }

  .icon .menu::before {
    top: -6px;
  }

  .icon .menu::after {
    top: 6px;
  }

  #menu:checked + .icon .menu {
    background: transparent;
  }

  #menu:checked + .icon .menu::before {
    transform: rotate(45deg);
  }

  #menu:checked + .icon .menu::after {
    transform: rotate(-45deg);
  }

  #menu:checked + .icon .menu::before,
  #menu:checked + .icon .menu::after {
    top: 0;
    transition: top ease 0.3s, transform ease 0.3s 0.3s;
  }

  .sidebar-menu {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.66s ease;
    z-index: 100;
    overflow: hidden;
    padding-bottom: 40px;
    height: 0px;
  }
  .sidebar {
    position: fixed;
    /* height: 0 !important; */
  }
  #menu:checked ~ .sidebar-menu {
    height: auto;
    transform: scaleY(1);
  }
  /* #menu:checked ~ .sidebar {
    height: auto;
  } */
}

.mainlogin {
  position: absolute;
  margin: auto;
  display: block;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 350px;
  height: 600px;
  background: red;
  overflow: hidden;
  background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38")
    no-repeat center/ cover;
  border-radius: 10px;
  box-shadow: 5px 20px 50px #000;
  text-align: center;
}
@media only screen and (min-width: 990px) {
  .mainlogin {
    left: 70%;
  }
}
#chk {
  display: none;
}
.signup {
  position: relative;
  box-sizing: unset;
  width: 100%;
  height: 130%;
  margin-bottom: 175px;
  margin-top: 150px;
}
.label-login {
  color: #fff;
  font-size: 2.3em;
  justify-content: center;
  display: flex;
  margin: 60px;
  margin-bottom: 5px;
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.label-login2 {
  margin-bottom: 50px;
}
.input-login {
  width: 60%;
  height: 40px;
  background: var(--background-secondary);
  color: var(--text-primary);
  justify-content: center;
  display: flex;
  margin: 20px auto;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}
.input-login-section {
  background-color: var(--background);
}
.input-login.form-error {
  border: 1px solid red;
  /* background-color: #ffecec; */
  color: rgb(255, 149, 149);
}
.button-login {
  width: 60%;
  height: 40px;
  margin: 10px auto;
  justify-content: center;
  display: block;
  color: #fff;
  background: rgba(68, 151, 203, 255);
  font-size: 1em;
  font-weight: bold;
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
  margin-top: 100px;
}

.sidebar .discussions .single .active:last-of-type,
sidebar .discussions .single:last-of-type {
  border-bottom: 4px solid rgb(70, 126, 70) !important;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Jost", sans-serif;
  /* background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e); */
}

.sidebar {
  min-height: 50px !important;
  max-height: 94% !important;
  z-index: 100;
  width: 100%;
}

.main {
  margin-top: 50px !important;
}

.layout {
  display: flex;
  overflow: scroll !important;
  flex-direction: row; /* Set the main axis to vertical */
  height: 100vh; /* Set the height to 100% of the viewport */
}
.tab-content {
  height: 100%;
}
#list-chat {
  height: 100%;
}
#content {
  height: 90%;
}

/* Set the justify-content property to space-between */
/* .layout {
  justify-content: space-between;
} */

.test-frame {
  background: #e0dede;
  display: flex;
  margin: auto;
  margin-top: 10%;
  padding: 20px;
  width: 80%;
  height: 80%;
  margin-bottom: 10%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(131.83deg, #fffafa 0%, #95a375 99.21%);
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(70, 126, 70);
}

.lesson-frame {
  height: auto;
}

.test-question {
  border: 1px solid rgb(66, 86, 143);
  height: auto;
  margin-top: 10px;
  padding: 15px;
  border-radius: 20px;
}

.test-answer {
  margin-top: 60px;
}

.test-button {
  width: 100%;
  background: rgb(151, 196, 151);
  padding: 10px;
  color: white;
  margin-top: 10px;
  border-radius: 10px;
}

.test-info {
  border: 1px solid green;
  background-color: antiquewhite;
  border-radius: 20px;
  color: green;
  padding: 10px;
  margin-top: 10%;
}

.test-info-p {
  font-size: 20px;
}

.lesson-title {
  color: rgb(17, 12, 12);
  margin-bottom: 0px;
}

.select {
  width: 40%;
  text-align: center;
  padding: 3px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.lesson-info {
  border: 0.5px solid green;
  padding: 5px;
  margin-top: 25px;
  border-radius: 10px;
  color: black;
}

.text-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
}

.text-links {
  display: flex;
  flex-direction: row;
}

.text-links a {
  margin-left: 20px;
  font-size: 20px;
}

.text-links a:hover {
  font-size: 25px;
  color: #47b74b !important;
  cursor: pointer;
}

.text-content-frame {
  width: 90%;
  padding: 15px;
  border: 1px solid green;
  border-radius: 10px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 990px) {
  .text-content-frame {
    width: 50%;
  }
  .banner-card {
    width: 50% !important;
  }
  .product-top {
    width: 50% !important;
  }
}

.text-content-frame img,
.text-content-frame .img {
  width: 100%;
}

.text-button {
  background-color: var(--background);
  border-radius: 20px;
  border: 1px solid green;
  color: var(--text-primary);
  padding: 5px 15px;
  position: relative;
  top: 32px;
}

.text-button:hover {
  background-color: green;
  color: white;
  cursor: pointer;
  border: 1px solid white;
}

.break-select {
  padding: 5px 15px;
  background-image: linear-gradient(131.83deg, #fffafa 0%, #a0a88d 99.21%);
  border-radius: 10px;
  margin-bottom: 10px;
  width: 20%;
}

.break-delete {
  padding: 0;
  position: relative;
  top: 5px;
}

@media only screen and (max-width: 990px) {
  .break-select {
    width: 40%;
  }
}

#favorite-i {
  position: relative;
  top: 4px;
  color: red;
}

#ad-view-i {
  position: relative;
  top: 7px;
}

.favorite-button {
  padding-top: 0px;
  padding-bottom: 0px;
  top: 36px;
}

.favorite-button:hover {
  background-color: white;
  border: 1px solid green;
}

.favorite-p {
  position: relative;
  bottom: 3px;
}

/* SLIDER */
.prev,
.next {
  cursor: pointer;
  position: relative;
  width: auto;
  padding: 10px;
  color: black;
  background-color: white;
  border: 1px solid green;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  left: 50%;
  border-radius: 3px 0 0 3px;
}
.prev {
  right: 50%;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.error {
  background-color: rgb(97, 20, 20) !important;
  color: white;
}

.info {
  background-color: green !important;
  color: white;
}

p,
div.text h6 {
  white-space: pre-wrap;
}

.text img {
  width: 100%;
}

#waveform {
  pointer-events: none;
}

.answer-button {
  display: block;
  width: 100%;
  margin: 10px;
  border-radius: 10px;
}
/* 
.answer-button: {
  background-color: blue;
} */

.selected {
  background-color: rgb(178, 178, 243);
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 80%;
  color: #757575;
  padding: 5px;
}

[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.modal {
  font-size: 12px;
  position: unset;
  display: block;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  font-size: 16px;
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  font-size: 18px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.bold {
  font-size: 17px;
  font-weight: bold;
}

.error-span {
  color: red;
  margin: auto;
  font-size: 10px;
  display: block;
  text-align: center;
}

.password-error {
  margin-bottom: 2px;
}

.navigation .inside .menu .material-icons.active {
  color: rgb(70, 126, 70);
  fill: rgb(70, 126, 70);
}
a:hover {
  color: rgb(70, 126, 70);
}
.sidebar .discussions .single.active {
  border-bottom: 4px solid rgb(70, 126, 70);
}

/* TOGGLE BUTTON */
.toggle-btn {
  position: relative;
  bottom: 60px;
  width: 60px;
  height: 30px;
  margin: 0 auto;
  margin-right: 10px;
  border-radius: 40px;
}

input[type="checkbox"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}
#_1st-toggle-btn span {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  opacity: 1;
  border: 1px solid var(--text-primary);
  border-radius: 40px;
  transition: 0.2s ease background-color, 0.2s ease opacity;
}

#_1st-toggle-btn span:before,
#_1st-toggle-btn span:after {
  content: "";
  position: absolute;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.5s ease transform, 0.2s ease background-color;
}

#_1st-toggle-btn span:before {
  background-color: #fff;
  transform: translate(-58px, 0px);
  z-index: 1;
}

#_1st-toggle-btn span:after {
  background-color: yellow;
  transform: translate(8px, 0px);
  z-index: 0;
}

#_1st-toggle-btn input[type="checkbox"]:checked + span {
  background-color: #000;
}

#_1st-toggle-btn input[type="checkbox"]:active + span {
  opacity: 0.5;
}

#_1st-toggle-btn input[type="checkbox"]:checked + span:before {
  background-color: #000;
  transform: translate(45px, -6px);
}

#_1st-toggle-btn input[type="checkbox"]:checked + span:after {
  background-color: #fff;
  transform: translate(35px, 0px);
}

@media only screen and (min-width: 990px) {
  .toggle-btn {
    position: relative;
    top: -30px;
    width: 60px;
    height: 30px;
    margin: 0 auto;
    margin-right: 30px;
    border-radius: 40px;
  }
}

.about-container h4,
.about-container p {
  text-align: center;
}
.about-container p {
  margin-bottom: 20px;
}

.about-container {
  padding: 15px;
}
#scrolltop {
  position: absolute;
  top: 0;
}
.notification-container {
  position: relative;
  width: 100%;
  bottom: 5px;
  left: 5px;
}

.notification-badge {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  display: inline-block;
  position: absolute;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: rgb(255, 255, 255) !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: rgb(225 77 73); /* rgb(212, 19, 13); */
  border-radius: 10px;
  top: -2px;
  right: -2px;
  transform: scale(1, 1);
}

.notification-container-sidebar {
  top: 10px;
  left: -10px;
}

.notification-badge-sidebar {
  padding: 8px 10px;
  border-radius: 20px;
}
.notification-badge-menu {
  display: none;
}
@media only screen and (max-width: 990px) {
  .notification-badge-menu {
    display: block;
    top: -32px;
    left: 15px;
    width: 20px;
    z-index: 4;
  }
}

button.button.button-login {
  padding: 0;
}

.break-top {
  width: 100%;
}

.break-top .break-select {
  line-height: initial;
  margin-right: 20px;
}

.break-top .break-input {
  width: 40%;
  padding: 5px;
  text-align: center;
}

.next-button {
  border-radius: 30px;
  width: 70%;
  height: 10px;
  line-height: 0;
}

.contact-card {
  width: 80%;
  font-size: 20px;
  margin: auto;
  text-align: center;
  border: 2px solid green;
  border-radius: 30px;
  padding: 30px;
  margin-top: 30%;
  background-image: linear-gradient(131.83deg, #fffafa 0%, #ced4c0 99.99%);
}

.banner-card {
  width: 90%;
  border: 2px solid green;
  border-radius: 10px;
  height: 100px;
  padding: 5px;
  margin-bottom: 30px;
}

.banner-card img {
  width: 50%;
  height: 100%;
}

.banner-card img:first-child {
  border-right: 2px solid green;
}

/* .break-delete {
  color: red;
  background-color: white;
} */

.product-table {
  width: 100%;
}

.margin-bottom-px {
  margin-bottom: -20px;
}

.product-row {
  border-bottom: 2px solid grey;
}

.product-title {
  padding: 10px;
  font-size: 20px;
  text-align: left;
}

.product-title span {
  float: right;
}

.product-info {
  padding: 10px;
  font-size: 20px;
  text-align: right;
}

.product-about {
  font-size: 20px;
  margin-top: 10px;
}

.product-price:first-child {
  border-right: 1px solid grey;
}

.product-market-info {
  border-top: 1px solid grey;
  margin-top: 5px;
  padding-top: 10px;
}

.product-div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid black;
  /* justify-content: space-around; */
}

.product-div input {
  width: 100%;
  border: none;
  flex-shrink: 0;
  background-color: transparent;
}

.product-div span {
  background-color: transparent;
  border: none;
  color: rgb(226, 97, 97);
  position: relative;
  right: 18px;
  /* top: 3px; */
}

.product-top {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.product-top .break-select {
  line-height: initial;
  margin-right: 20px;
  width: 40%;
}

.product-top .break-select.padding-0 {
  padding: 0 15px;
}

.product-top .break-select.padding-0.button-favorite {
  width: 20%;
}

.product-top .break-select.padding-0.select-categories {
  width: 55%;
}

.disabled-link {
  pointer-events: none;
  opacity: 50%;
}

.break-button {
  width: 19% !important;
  height: 40px !important;
}

@media only screen and (max-width: 990px) {
  .break-button {
    width: 40% !important;
    height: 40px !important;
  }
  .break-button:last-child {
    height: 100% !important;
  }
}

.admin-form {
  width: 80%;
  margin: auto;
  border: 1px solid rgb(31, 190, 230);
  border-radius: 10px;
  text-align: center;
}

.admin-form-element {
  margin: 20px auto;
  min-height: 30px;
  border-radius: 5px;
  width: 80%;
  border: 1px solid green;
}

.admin-form-element input {
  width: 100%;
  overflow: hidden;
}

.admin-form-div {
  margin: 20px auto;
  text-align: center;
  padding: 10px;
  min-height: 30px;
  border-radius: 5px;
  width: 80%;
  border: 1px solid green;
}

.admin-form-div span {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.admin-form-element img {
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.admin-form-button {
  background-color: var(--background);
  border-radius: 20px;
  border: 1px solid green;
  color: var(--text-primary);
  padding: 5px 15px;
  position: relative;
  top: 17px;
}

.admin-form-element-error {
  border: 2px solid red;
}

.admin-form-error-span {
  display: block;
  color: red;
  font-size: 16px !important;
}

.admin-form-div span {
  margin-bottom: -10px !important;
}

.loader {
  margin: auto;
  bottom: 50%;
  left: 40%;
  position: absolute !important;
}

@media only screen and (max-width: 990px) {
  .calculate {
    width: 33px;
  }
}

.test-button {
  color: black;
}

.geography-answers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.geography-answers img {
  width: 42.5%;
  height: 100px;
}

.geography-answers .selected.error {
  background-color: var(--background-secondary);
  padding: 10px;
  border: 2px solid red;
}

.geography-answers .selected.correct {
  background-color: var(--background-secondary);
  padding: 10px;
  border: 2px solid green;
}

.geography-message .buttons button {
  border: 1px solid black;
  background-color: #122b12;
  color: white;
  margin-right: 5px;
  border-radius: 5px;
  margin-top: 10px;
}

.geography-audio-button {
  float: right;
  font-size: 35px;
  position: relative;
}

.geography-message-2 {
  padding: 10px;
}

.geography-message-2 img {
  width: 100%;
}

.geography-answers-2 {
  display: flex;
  flex-direction: column;
}

.geography-answers-2 button {
  width: 100%;
  margin: auto;
  margin-top: 10px;
  padding: 7px;
  border-radius: 10px;
}

.selected.correct-2 {
  background-color: rgb(128 203 128);
  padding: 10px;
}

.reset-link {
  padding-left: 50px;
  display: block;
  width: 250px;
}

.input-reset {
  width: 80%;
  height: 40px;
  background: var(--background);
  color: var(--text-primary);
  justify-content: center;
  display: flex;
  margin: 20px auto;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid var(--text-primary);
}

.input-reset.button {
  background-color: rgb(70, 126, 70);
}

.profile-reset {
  height: 80% !important;
}

.input-reset.form-error {
  border: 1px solid red;
  /* background-color: #ffecec; */
  color: rgb(255, 149, 149);
}

.reset-error-span {
  color: red;
  margin: auto;
  font-size: 15px;
  display: block;
  text-align: center;
}

.profile-h5 {
  margin-top: 100px;
}

.chemistry-correct {
  color: var(--text-primary);
  background-color: var(--background-secondary);
  border: 2px solid green;
}

.chemistry-error {
  color: var(--text-primary);
  background-color: var(--background-secondary);
  border: 2px solid red;
}

.chemistry-element-card {
  border: 2px solid var(--text-primary);
  padding: 10px;
  width: 100%;
  height: 140px;
  position: relative;
}

.chemistry-element-card .name {
  position: absolute;
  right: 15px;
  bottom: 0px;
  color: rgb(124, 124, 255);
}

.chemistry-element-card .symbol {
  position: absolute;
  right: 15px;
  color: rgb(255, 83, 83);
}

.chemistry-element-card .order {
  margin-left: 10px;
  margin-bottom: 5px;
}

.games-list {
  display: flex;
  width: 100%;
  position: relative;
}

.games-list i {
  position: relative;
  width: 60px;
  top: 10px;
}

.blitz-start-button {
  width: 100%;
  margin-top: 30px;
  height: 50px;
  background: var(--background-secondary);
  border: 2px solid #3e98c7;
  color: #3e98c7;
  border-radius: 10px;
}

.blitz-select-button {
  margin: 5px;
}

.operation-buttons {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.operation-buttons button {
  margin: 0 5px;
  margin-top: 15px;
  background-color: transparent;
  border: 2px solid #3e98c7;
  border-radius: 5px;
  color: #3e98c7;
  font-size: 40px;
  width: 46%;
  height: 50px;
  line-height: normal;
}

.operation-next-button {
  width: 100%;
  background-color: transparent;
  color: white;
}

.operation-buttons button:disabled {
  border: 2px solid #426f87;
}

.button-login:hover {
  background: rgb(51, 95, 51);
}
.login {
  height: 600px;
  background: var(--background-secondary);
  border-radius: 60% / 10%;
  transform: translateY(-100px);
  transition: 0.8s ease-in-out;
  padding: 50px;
}
.login .label-login {
  color: rgb(70, 126, 70);
  transform: scale(0.6);
}
.signup .label-login {
  color: rgb(70, 126, 70);
}
#chk:checked ~ .login {
  transform: translateY(-670px);
}
#chk:checked ~ .login .label-login {
  transform: scale(1);
}
#chk:checked ~ .signup .label-login {
  transform: scale(0.6);
}

.button-login-by-id {
  margin-top: 30px;
}

.button-login-by-id.bg-green {
  background-color: rgb(70, 126, 70);
}

.text-button-top {
  position: unset;
  margin: 20px;
  width: 70%;
}

.image-select-label {
  position: relative;
  top: 15px;
}

.container .col-md-12 .file-preview {
  background-color: red;
  height: 200px;
  position: relative;
  padding: 10px;
  text-align: center;
  border: 2px solid #bdbac2;
  background: var(--background-secondary);
  border-bottom: 0;
}

.container .col-md-12 .file-preview .preview-container {
  max-width: 100%;
  max-height: 180px;
  position: relative;
}

.preview-container .img {
  max-width: 100%;
  max-height: 180px;
}

.container .col-md-12 .file-preview .preview-container .remove {
  background-color: red;
  border: 0;
  color: white;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.chat-img {
  width: 200px !important;
}

.image-container {
  padding: 2px !important;
  text-align: center;
}

.p-image {
  margin: 5px;
}

.react-select__menu-list {
  display: flex;
  flex-wrap: wrap;
}

.react-select__menu-list div {
  width: auto;
  border: 1px solid black;
  margin: 5px;
  padding: 3px 10px;
  border-radius: 7px;
}

.react-select__option--is-selected {
  background: transparent !important;
  color: inherit !important;
  opacity: 0.5;
}

.markets-delete-icon {
  background: transparent;
  border: 0;
  color: red;
  right: 25px;
  position: relative;
}

.button-login-h6-text {
  margin-bottom: -80px;
  margin-top: 80px;
}

.button-login-mini {
  margin: 10px auto;
}

.feedback-body {
  color: red;
  border: 1px solid red;
}

.admin-form-div textarea {
  width: 90%;
}

/* .carousel {
  width: 100%;
  height: 400px;
} */

/* .carousel .outer-container {
  height: 100%;
  overflow: hidden;
  box-shadow: 5px 5px 20px 7px rgba(168, 168, 168, 1);
}

.carousel .outer-container .inner-container {
  display: flex;
  flex-direction: column;
}

.carousel .outer-container .inner-container .item-container {
  flex: 1 0 100%;
  flex-basis: 80%;
}

 */
.custom-carousel-item {
  text-align: center;
}
.custom-carousel-item img {
  max-width: 100%;
  height: 400px;
}

.text.active {
  color: var(--text-primary) !important;
  background-color: #44aa8866 !important;
}

.profile-progress-link {
  border: 1px solid rgb(70, 126, 70);
  margin: 10px;
  border-radius: 5px;
}

.chooseLessonSelect {
  width: unset !important;
}
