@import url("https://fonts.googleapis.com/css?family=Krub:400,700");

.profile-card {
  display: flex;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 500px;
  border: 1px solid #555555;
  border-radius: 10px;
  box-shadow: 0 10px 25px 5px rgba(0, 0, 0, 0.4);
  background: var(--background-secondary);
  overflow: hidden;
}

.profile-card .profile-ds-top {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  width: 300px;
  height: 80px;
  background: rgb(70, 126, 70);
}

.profile-card .profile-avatar-holder {
  position: absolute;
  margin: auto;
  top: 40px;
  right: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #151515, inset 0 0 0 5px #000000,
    inset 0 0 0 5px #000000, inset 0 0 0 5px #000000, inset 0 0 0 5px #000000;
  background: white;
  overflow: hidden;
}

.profile-card .profile-avatar-holder .profile-avatar {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.profile-card .profile-name {
  position: relative;
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: inherit;
  height: 190px;
  text-align: center;
  color: var(--text-primary);
}

.profile-card .profile-name h6 {
  margin: 10px auto;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
}

.profile-card .profile-logout {
  margin: 10px auto;
  padding: 4px;
  right: 0;
  bottom: 40px;
  left: 0;
  width: 70%;
  height: 30px;
  text-align: center;
  outline: none;
  background-color: rgb(255, 165, 165);
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  box-shadow: 0 5px 30px 5px rgba(255, 0, 0, 0.4);
}

.profile-update {
  margin: auto;
  padding: 4px;
  right: 0;
  bottom: 40px;
  left: 0;
  width: 70%;
  height: 30px;
  text-align: center;
  outline: none;
  background-color: rgb(171 165 255);
  border: 1px solid blue;
  border-radius: 5px;
  color: blue;
  box-shadow: 0 5px 30px 5px #4444ff;
}

.profile-page {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-update {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  width: 90%;
  height: 30px;
}

.dropdown-menu {
  position: relative;
  will-change: transform;
  top: 0px;
  right: -50px;
}

.chat-more-button {
  cursor: pointer;
}
